import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";
import {Link} from 'gatsby';
import SEO from '../../components/seo'

import imgHeader from "../../assets/img/realisations/flying_0.jpg";

export default () => (
  <div id="top">
    <Menu />
    <SEO 
    title="Flying Fish | Agence digitale | MDS Digital Agency"
    description="MDS collabore avec Flying Fish pour un projet international de mise en place et gestion de tags de sites internet."
    keywords="Flying Fish"
    
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>
   
      <div className={workStyle.info_cell}>
        <h3>Services</h3>

        <span className={workStyle.badge}><Link to="/services/developpement/">Développement</Link></span>

      </div>
      <div className={workStyle.info_cell}>
      <h3>Secteur</h3>
        <p>Web Analytics</p>
        <h3>Localisation</h3>
        <p>Louvain-la-Neuve</p>
      </div>

      <div className={workStyle.info_cell}>
        <h3>Lien </h3>
        <p>
          <a
            href="http://www.flyingfish.agency/"
            target="_blank"
            rel="noopener noreferrer"
            className={workStyle.external_link}
          >
           flyingfish.agency
          </a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>Flying Fish</h2>
      <p>
        Flying Fish est une agence digitale spécialisée dans le traitement et
        l’interprétation des données numériques pour des fins stratégiques,
        marketing.
      </p>
      <p> Nous nous chargeons de la gestion quotidienne des
        <strong> implémentations de tags via Google Tag Manager ou Signal Tag</strong> pour tous
        les sites Toyota gérés par Toyota Motor Europe. 
      </p>
      <p>
        Grâce à ces tags, les
        campagnes marketing sont plus vite implémentées sur les sites web et
        leurs résultats sont monitorés avec plus de précision.
      </p>
      <div className={pageStyle.cta}>
      <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
